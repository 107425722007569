import Loadable from '../components/Loadable';

const IndexPengingat = Loadable(() => import('../views/Pengingat/Index'));
const IndexPasien = Loadable(() => import('../views/Pasien/Index'));
const IndexPemasok = Loadable(() => import('../views/Pemasok/Index'));
const IndexPlayground = Loadable(() => import('../views/Playground/Index'));
const IndexTarif = Loadable(() => import('../views/Tarif/Index'));
const IndexDiagnosis = Loadable(() => import('../views/Diagnosis/Index'));
const IndexTindakan = Loadable(() => import('../views/Tindakan/Index'));
const IndexObat = Loadable(() => import('../views/Obat/Index'));
const IndexPembelian 	= Loadable(() => import('../views/Pembelian/Index'));
const IndexPemeriksaan 	= Loadable(() => import('../views/Pemeriksaan/Index'));
const IndexKonsultasi 	= Loadable(() => import('../views/Konsultasi/Index'));
const IndexKunjungan 	= Loadable(() => import('../views/Kunjungan/Index'));
const IndexLaporan 	= Loadable(() => import('../views/Laporan/Index'));

const DashboardContent = [
	{ id: "", icon: "game", path: "/playground", title: "Playground", component: IndexPlayground },
	{ id: "pengingat", icon: "bell", path: "/pengingat", title: "Pengingat", component: IndexPengingat },
	{ id: "pasien", icon: "address book", path: "/pasien", title: "Pasien", component: IndexPasien },
	{ id: "pemasok", icon: "ambulance", path: "/pemasok", title: "Pemasok", component: IndexPemasok },
	{ id: "tarif", icon: "money bill alternate outline", path: "/tarif", title: "Tarif", component: IndexTarif },
	{ id: "diagnosis", icon: "treatment", path: "/diagnosis", title: "Diagnosis", component: IndexDiagnosis },
	{ id: "tindakan", icon: "first aid", path: "/tindakan", title: "Tindakan", component: IndexTindakan },
	{ id: "obat", icon: "pills", path: "/obat", title: "Obat", component: IndexObat },
	{ id: "pembelian_obat", icon: "plus cart", path: "/pembelian", title: "Pembelian Obat", component: IndexPembelian },
	{ id: "pemeriksaan", icon: "doctor", path: "/pemeriksaan", title: "Pemeriksaan", component: IndexPemeriksaan },
	{ id: "konsultasi", icon: "doctor", path: "/konsultasi", title: "Konsultasi", component: IndexKonsultasi },
	{ id: "kunjungan", icon: "calendar alternate outline", path: "/kunjungan", title: "Kunjungan", component: IndexKunjungan },
	{ id: "laporan", icon: "newspaper outline", path: "/laporan", title: "Laporan", component: IndexLaporan },
];

export default DashboardContent;