import _ from 'lodash';
import Axios from 'axios';
import { BASE_URL_DEV, BASE_URL_PROD } from './config';

Axios.defaults.withCredentials = true;

const { NODE_ENV } = process.env;

let URL = null;
if (NODE_ENV === 'production') {
	URL = BASE_URL_PROD;
} else {
	URL = BASE_URL_DEV;
}

const generateBase = (base) => ({
	index: (query) => Axios.get(base, { params: query }).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
	show: (id) => Axios.get([base, id].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
	store: (data) => Axios.post(base, data).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
	update: (id, data) => Axios.put([base, id].join('/'), data).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
	delete: (id) => Axios.delete([base, id].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
});

/**
 * Pengguna Resource
 */
export const pengguna = (() => {
	const base = [URL, 'pengguna'].join('/');
	return _.assign({}, generateBase(base), {
		check: () => Axios.get([base, 'check'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
		login: (body) => Axios.post([base, 'login'].join('/'), body).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
		logout: () => Axios.post([base, 'logout'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
		indexDokter: () => Axios.get([base, 'dokter'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Pasien Resource
 */
export const pasien = (() => {
	const base = [URL, 'pasien'].join('/');
	return _.assign({}, generateBase(base), {
		showMedicalRecord: (id) => Axios.get([base, id, 'rekam-medis'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Pembelian Resource
 */
export const pembelian = (() => {
	const base = [URL, 'pembelian'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Pemasok Resource
 */
export const pemasok = (() => {
	const base = [URL, 'pemasok'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Tarif Resource
 */
export const tarif = (() => {
	const base = [URL, 'tarif'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Diagnosis Resource
 */
export const diagnosis = (() => {
	const base = [URL, 'diagnosis'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Procedure Resource
 */
export const procedure = (() => {
	const base = [URL, 'procedure'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Tindakan Resource
 */
export const tindakan = (() => {
	const base = [URL, 'tindakan'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Obat Resource
 */
export const obat = (() => {
	const base = [URL, 'obat'].join('/');
	return _.assign({}, generateBase(base), {
		laporan: (query) => Axios.get([base, 'laporan'].join('/'), { params: query, responseType: 'blob' }).then((response) => {
			if (response.status === 200) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${_.compact(['laporan', query.tipe]).join('-')}.xlsx`); //or any other extension
				document.body.appendChild(link);
				link.click();
			}
			return response.status;
		}).catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Pemeriksaan Resource
 */
export const pemeriksaan = (() => {
	const base = [URL, 'pemeriksaan'].join('/');
	return _.assign({}, generateBase(base), {
		update: (id, data) => Axios.put([base, id, 'transaksi'].join('/'), data).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
		laporan: (query) => Axios.get([base, 'laporan'].join('/'), { params: query, responseType: 'blob' }).then((response) => {
			if (response.status === 200) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'laporan-pemeriksaan.xlsx'); //or any other extension
				document.body.appendChild(link);
				link.click();
			}
			return response.status;
		}).catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Kunjungan Resource
 */
export const kunjungan = (() => {
	const base = [URL, 'kunjungan'].join('/');
	return _.assign({}, generateBase(base), {
		delete: (id) => Axios.delete([base, id, 'transaksi'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {} )),
		clear: () => Axios.delete([base, 'clear'].join('/')).then((resp) => resp.data).catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Konsultasi Resource
 */
export const konsultasi = (() => {
	const base = [URL, 'konsultasi'].join('/');
	return _.assign({}, generateBase(base));
})();

/**
 * Pengingat Resource
 */
export const pengingat = (() => {
	const base = [URL, 'pengingat'].join('/');
	return _.assign({}, generateBase(base));
})();

export default {
	pengguna, pasien, pembelian, konsultasi,
	tindakan, procedure, diagnosis, obat,
	pemeriksaan, kunjungan
};