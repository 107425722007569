import _, { isBoolean } from 'lodash';
import React from 'react';
import { Form, Input, Button, Icon, Message } from 'semantic-ui-react';
import Flex from '../../components/Flex';

const PenggunaForm = ({ loading, error = {}, user = {}, handleChange, handleClick, success }) => {
	const is_error = !_.isEmpty(error.header);
	const is_success = success === true;
	return (
		<Form error={is_error} success={is_success}>
			<Form.Group widths="equal">
				<Form.Field required> <label>Username</label> <Input fluid placeholder="Username" name="username" value={user.username} onChange={handleChange} disabled/> </Form.Field>
				<Form.Field required> <label>Nama Lengkap</label> <Input fluid placeholder="Nama Lengkap" name="nama" value={user.nama} onChange={handleChange} /> </Form.Field>
				<Form.Field required> <label>Nama Panggilan</label> <Input fluid placeholder="Nama Panggilan" name="panggilan" value={user.panggilan} onChange={handleChange} /> </Form.Field>
			</Form.Group>
			<Form.Group widths="equal">
				<Form.Field> <label>No Telp</label> <Input fluid placeholder="No Telephone / No HP" name="no_telp" value={user.no_telp} onChange={handleChange} /> </Form.Field>
				<Form.Field> <label>Alamat</label> <Input fluid placeholder="Alamat" name="alamat" value={user.alamat} onChange={handleChange} /> </Form.Field>
			</Form.Group>
			<Form.Group widths="equal">
				<Form.Field> <label>Current Password</label> <Input type="password" fluid placeholder="Current Password" name="password" value={user.password} onChange={handleChange} /> </Form.Field>
				<Form.Field> <label>New Password</label> <Input type="password" fluid placeholder="New Password" name="new_password" value={user.new_password} onChange={handleChange} /> </Form.Field>
				<Form.Field> <label>Re type password</label> <Input type="password" fluid placeholder="Re type password" name="re_password" value={user.re_password} onChange={handleChange} /> </Form.Field>
			</Form.Group>

			<Message error header={error.header} list={error.list} />
			{isBoolean(success) && <Message success={is_success} header={"Data pengguna berhasil diperbarui"}/>}

			<Flex direction="row-reverse">
				<Button loading={loading} disabled={loading ||is_error} style={{ margin: '0 .6em' }}onClick={handleClick} color="teal" name="save"><Icon name="save"/>Simpan</Button>
				<Button loading={loading} style={{ margin: '0 .6em' }}onClick={handleClick} name="close"><Icon name="close"/>Tutup</Button>
			</Flex>
		</Form>
	);
};

export default PenggunaForm;