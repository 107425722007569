import _ from 'lodash';
import styled from 'styled-components';
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Modal, Header, Icon, Label } from 'semantic-ui-react';

import DashboardRoutes from '../routes/Dashboard';
import HeaderComp from '../views/Header/Header';

import { sidebar, header } from '../variables';
import { ModalProvider, UserContext } from '../context';

const BaseContainer = styled.div`
	display: grid;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	grid-template-columns: ${sidebar.width} 1fr;
	grid-template-rows: ${header.height} auto;
`

const SidebarContainer = styled.div`
	width: 100%;
	padding-left: .4em;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #fefefe;
	border-right: solid 1px #eee;
`
const HeaderContainer = styled.div`
	width: 100%;
	display: flex;
	border-bottom: solid 1.2px #eaeaea;
	grid-column-start: 1;
	grid-column-end: 3;
	alignItems: center;
	padding: 1em;
`
const MainContentContainer = styled.div`
	padding: .8em;
	max-height: 100%;
	max-width: 100%;
	background: #f9f9f9;
	overflow: auto;
`

const MenuContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const MenuItem = styled.a`
	display: block;
	font-size: 1.1em;
	font-weight: 400;
	padding: .8em;
	user-select: none;
	color: #aaa;
	border-left: solid 4px #fff;

	.menu-item.menu-label {
		position: absolute !important;
		z-index: 1010;
		display: none;
	}
	
	&:hover {
		cursor: pointer;
		background: #eee;
		border-left: solid 4px #eee;
		color: #e03997;

		.menu-item.menu-label {
			display: inline-flex;
		}
	}
`;
const MenuItemActive = styled(MenuItem)`
	font-weight: 600;
	color: #e03997;
	background: #fff;
	border-left: solid 4px #e03997;
	&:hover {
		cursor: pointer;
		background: #fff;
		border-left: solid 4px #e03997;
		color: #e03997;
	}
`;
const defautlModalOpts = {
	open: false,
	size: "tiny",
	centered: false,
	content: <Header content="Modal content" />
};
class DashboardLayout extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: defautlModalOpts,
			is_expand: false,
		};

		this.setExpand = this.setExpand.bind(this);
		this.buildModal = this.buildModal.bind(this);
	}

	componentDidMount() {
		if (_.get(this.props, 'user.data.is_login') !== true) {
			console.log('Redirect to Login Page');
			return this.props.history.push('/login');
		}

		const peran = _.get(this.props, 'user.data.peran');
		const pathname = window ? window.location.pathname : '/';
		
		if (pathname === '/' && peran === 'dokter') {
			return this.props.history.push('/konsultasi');
		}

		if (pathname === '/') {
			return this.props.history.push('/kunjungan');
		}

	}

	setExpand(bool) { this.setState({ is_expand: bool }); }

	buildModal(modal) {
		this.setState({ modal: _.assign({}, defautlModalOpts, modal) });
	}

	render() {
		const { modal, is_expand } = this.state;
		const pathname	= _.get(this.props, 'location.pathname');
		const acl				= _.chain(this.props).get(['user', 'rule'], {}).keys().value();
		const is_menu		= !_.isEmpty(pathname) && pathname.length > 1;

		const DashRoutes	= DashboardRoutes.filter(item => _.includes(acl, item.id));
		return <BaseContainer style={{ gridTemplateColumns: `${is_expand ? sidebar.width : '5em' } 1fr` }}>
			<HeaderContainer> <HeaderComp is_expand={is_expand} setExpand={this.setExpand}/> </HeaderContainer>
			<SidebarContainer>
				<MenuContainer>
					{DashRoutes.map((item, key) => {
						if (is_menu && pathname.match(new RegExp(`^${item.path}`, 'gi'))) {
							return (<MenuItemActive key={['menu', key].join('-')} onClick={() => this.props.history.push(item.path)}>
								<Icon size="large" name={item.icon}> </Icon>
								{is_expand
									? <span style={{ paddingLeft: '.6em' }}>{item.title}</span>
									: <Label className="menu-item menu-label" basic color='pink' float pointing='left'>{item.title}</Label>}
							</MenuItemActive>);
						} else {
							return (<MenuItem key={['menu', key].join('-')} onClick={() => this.props.history.push(item.path)}>
								<Icon size="large" name={item.icon} />
								{is_expand 
									? <span style={{ paddingLeft: '.6em' }}>{item.title}</span>
									: <Label className="menu-item menu-label" basic color='pink' float pointing='left'>{item.title}</Label>}
							</MenuItem>);
						}
					})}
				</MenuContainer>
			</SidebarContainer>
			<MainContentContainer>
				<Modal {..._.omit(modal, 'content')}>
					<Modal.Content>{modal.content}</Modal.Content>
				</Modal>
				<ModalProvider value={_.assign({}, modal, { buildModal: this.buildModal })}>
					<Switch>
						{DashRoutes.map((item, key) => <Route key={['main-content', key].join('-')} path={item.path} component={item.component} />)}
					</Switch>
				</ModalProvider>
			</MainContentContainer>
		</BaseContainer>
	}
}

export default (props) => <UserContext.Consumer>{(user) => <DashboardLayout {...props} user={user}/>}</UserContext.Consumer>