import _ from 'lodash';
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';

import Login from './views/Login/Login';
import Dashboard from './layouts/Dashboard';

import { UserContext } from './context';
import { pengguna } from './fetcher';

import rule from './rule.json';

class App extends Component {
	constructor(props) { 
		super(props);
		this.state = { is_loading: true };

		this.checkStatus = this.checkStatus.bind(this);
	}

	componentDidMount() { this.checkStatus(); }

	async checkStatus() {
		this.setState({ is_loading: true });
		const retval = await pengguna.check();
		const status = _.get(retval, 'status_code');
		const user = status === 200 ? _.get(retval, 'result', {}) : {};
		return this.setState({ user, is_loading: false });
	}

  render() {
		const { user, is_loading } = this.state;
		const UserCtx = { data: user, checkStatus: this.checkStatus };
		if (!_.isEmpty(user)) { UserCtx.rule = _.get(rule, user.peran, {}); }
		return (<UserContext.Provider value={UserCtx}>
			<BrowserRouter>
				{!is_loading && <Switch>
					<Route path='/login' component={Login} />
					<Route path='/' component={Dashboard} />
				</Switch>}
			</BrowserRouter>
		</UserContext.Provider>)
  }
}

export default App;
