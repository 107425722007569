import _ from 'lodash';

export const PERIKSA 			= 100;
export const MENUNGGU			= 50;
export const BELUM_HADIR 	= 10;
export const SELESAI 			= 0;

export const PAGI 	= 'PAGI';
export const SIANG 	= 'SIANG';
export const SORE 	= 'SORE';
export const MALAM 	= 'MALAM';

export const SMALL_DELAY = Number(process.env.REACT_APP_SMALL_DELAY || 10);
export const sapaan_enum = 'An. Nn. Sdr. Ny. Tn.'.split(' ');
export const diskon_opts = [
	{ value: 'NOMINAL', text: 'NOMINAL' },
	{ value: 'PERCENTAGE', text: 'PERCENTAGE' },
];
export const sapaan_opts = _.sortBy([
	{ value: 'An.',  text: 'Anak (An.)' },
	{ value: 'Nn.', text: 'Nona (Nn.)' },
	{ value: 'Sdr.', text: 'Saudara (Sdr.)' },
	{ value: 'Ny.', text: 'Nyonya (Ny.)' },
	{ value: 'Tn.', text: 'Tuan (Tn.)' },
], 'text');

export const sapaan_opts_short = _.sortBy([
	{ value: 'An.',  text: 'Anak (An.)' },
	{ value: 'Nn.', text: 'Nona (Nn.)' },
	{ value: 'Sdr.', text: 'Saudara (Sdr.)' },
	{ value: 'Ny.', text: 'Nyonya (Ny.)' },
	{ value: 'Tn.', text: 'Tuan (Tn.)' },
], 'text').map(o => { o.text = o.value; return o; });

export const bool_opts = _.sortBy([
	{ value: true,  text: 'TRUE' },
	{ value: false, text: 'FALSE' },
], 'text');

export const shift_opts = [
	{ value: PAGI, text: PAGI },
	{ value: SIANG, text: SIANG },
	{ value: SORE, text: SORE },
	{ value: MALAM, text: MALAM },
];

export const status_opts = [
	{ value: BELUM_HADIR, text: 'BELUM HADIR' },
	{ value: MENUNGGU, text: 'MENUNGGU' },
	{ value: PERIKSA, text: 'PERIKSA' },
	{ value: SELESAI, text: 'SELESAI' },
];

export const pembayaran_opts = [
	{ value: 'CASH', text: 'CASH' },
	{ value: 'DEBIT', text: 'DEBIT' },
	{ value: 'TRANSFER', text: 'TRANSFER' },
];

export const date_format = 'DD/MM/YYYY';
export const time_format = 'HH:mm:ss';
export const datetime_format= [date_format, time_format].join(' ');

export const table_item_limit = 25;

export const BIAYA_ADMIN = 2500;