import _ from 'lodash';
import numeral from 'numeral';

numeral.register('locale', 'id', {
	delimiters: { thousands: '.', decimal: ',' },
	abbreviations: {
		thousand: 'Ribu',
		million: 'Juta',
		billion: 'Miliar',
		trillion: 'Trilyun'
	},
	currency: { symbol: 'Rp' }
});

numeral.locale('id');

const numeric = (value) => {
	if (_.isEqual(value, 0))	{ return 'Rp 0,00'; }
	return numeral(value).format('$ 0,0.00')
};

export default numeric;