import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Input, Select } from 'semantic-ui-react';

import numeric from '../helpers/numeric';
import { bool_opts, datetime_format } from '../helpers/constant';

const InputCstm = (props) => {
	const { value, size = "medium" } = props;
	const input_props = _.omit({
		...props, size,
		fluid: _.isNil(props.fluid) ? true : props.fluid,
	}, 'label', 'width', 'is_edit');

	let is_edit = props.is_edit;
	if (props.ignored && props.disabled) { is_edit = false; }
	if (is_edit) {
		let type = 'text';
		switch (props.type) {
			case 'boolean':
				return <Select {...input_props} options={bool_opts} />
			case 'select':
				return <Select {...input_props}/>
			case 'date': {
				return <Input {...input_props} value={value} type={type}/>;
			}
			case 'datetime': {
				const temp = moment(value);
				let value_text = null;
				if (!temp.isValid() || _.isNil(value) || _.isEqual(value, 'NULL')) {
					value_text = 'NULL';
				}	else {
					value_text = temp.format(datetime_format);
				}
					
				return <Input {...input_props} value={value_text} type={type}/>;
			}
			case 'password': { type='password'; return <Input {...input_props} type={type} />; }
			case 'number':
			case 'numeric':
				type = 'number';
			default:
				return <Input {...input_props} type={type}/>;
		}
	} else {
		let text = '';
		switch (props.type) {
			case 'date':
			case 'datetime': {
				if (_.isNil(value) || _.isEqual(value, 'NULL')) { text = 'NULL'; break; }
				text = value; break;
			}
			case 'boolean': { text = (value).toString().toUpperCase(); break; }
			case 'numeric': { text = numeric(value || 0); break; }
			default:
				text = value;
		}
		if (_.isNil(text)) { text = '-'; }
		return <span>{text}</span>
	}
}

export default InputCstm;