import _ from 'lodash';
import styled from 'styled-components';

const ignored = 'children forwarded-class theme'.split(' ');
const Text = styled.div`
	${(props) => {
		let text = [];		
		_.mapValues(props, (v, k) => {
			if (_.includes(ignored, k)) { return; }
			text.push([ _.snakeCase(k).replace('_', '-'), v].join(': ') + ';');
		});
		return text.join(' ');
	}}
`

export default Text;