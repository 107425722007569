import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
	display: flex;
	width: 100%;
	flex-direction: ${({ direction }) => direction ? direction : 'row'};
	
	${({ margin }) => margin && `margin: ${margin};`}
	${({ padding }) => padding && `padding: ${padding};`}
	${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
	${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}	
`
const Flex = (props) => <Div {...props}/>;

export default Flex;