import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Segment, Grid, Form, Button, Message } from 'semantic-ui-react';

import Flex from '../../components/Flex';
import Text from '../../components/Text';
import FlexItem from '../../components/FlexItem';

import Input from '../../components/Input';

import bg from '../../images/bg-login.png';
import bgLeft from '../../images/bg-left.png';
import { compose, withState, withHandlers, lifecycle } from 'recompose';

import { pengguna } from '../../fetcher';
import { UserContext } from '../../context';

const Base = styled.div`
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	padding: 1em;
	overflow: auto;
	background-image: url(${bg});
	background-size: 100% 100%;
`

const Login = (props) => {
	const { error, handleChange, handleSubmit, loading } = props;
	return (
		<Base>
			<Flex justifyContent="center" alignItems="center" style={{ height: '100%' }}>
				<FlexItem style={{ width: '80%', maxWidth: '72em' }}>
					<Segment style={{ paddingTop: "0", paddingBottom: "0", backgroundColor: "rgba(255,255,255,.9)" }}>
						<Form>
						<Grid stackable>
							<Grid.Row columns={2} style={{ fontSize: '1.2em' }}>
								<Grid.Column width="6" style={{ color: 'white', backgroundSize: '100% 100%', backgroundImage: `url(${bgLeft})`, padding: "8em 2em" }}>
									<Grid.Row><Text fontFamily="'Lato', sans-serif" lineHeight="1.2em" fontSize="4em">Hello</Text></Grid.Row>
									<Grid.Row><Text fontFamily="'Lato', sans-serif" paddingTop="1em" lineHeight="1.2em" fontSize="1.2em" fontWeight="400">Please, enter your Personal Account.</Text></Grid.Row>
									<Grid.Row><Text fontFamily="'Lato', sans-serif" paddingTop="2em" lineHeight="1.2em" fontSize="2em" fontStyle="italic" fontWeight="700"textAlign="right">- Alaia</Text></Grid.Row>
								</Grid.Column>
								<Grid.Column width="10" textAlign="center" style={{ padding: '2em 1em', paddingBottom: '6em' }} verticalAlign="middle">
									<Grid.Row style={{ paddingBottom: "1.6em" }}>
										<Text color="#3aa7ae" fontFamily="'Montserrat', sans-serif" fontSize="3em" fontWeight="700">Login</Text>
									</Grid.Row>
									<Grid.Row style={{ paddingBottom: "5em" }}>
										<div style={{ margin: 'auto', height: '.3em', width: '10em', backgroundImage: 'linear-gradient(to right,#e43b9a,#3aa7ae)' }}/>
									</Grid.Row>
									<Form error={!_.isEmpty(error.header)}>
										<Grid.Row style={{ paddingBottom: "1.4em", paddingLeft: "2em", paddingRight: "2em" }}>
											<div style={{ maxWidth: '20em', margin: 'auto' }}><Input onChange={handleChange} icon="user" iconPosition="left" is_edit={true} name="username" placeholder="Username"/></div>
										</Grid.Row>
										<Grid.Row style={{ paddingBottom: "1.4em", paddingLeft: "2em", paddingRight: "2em" }}>
											<div style={{ maxWidth: '20em', margin: 'auto' }}><Input onChange={handleChange} icon="lock" iconPosition="left" is_edit={true} name="password" type="password" placeholder="Password"/></div>
										</Grid.Row>
										<Grid.Row>
											<div style={{ margin: 'auto' }}><Message compact error header={error.header} style={{ padding: '.8em', fontSize: '.8em', marginBottom: '1.4em' }}/></div>
										</Grid.Row>
										<Grid.Row> <Button onClick={handleSubmit} loading={loading} disabled={loading} content='SIGN IN' color="pink" padding="0 4em" style={{ padding: ".8em 3em", fontFamily: "'Montserrat', sans-serif" }}/> </Grid.Row>
									</Form>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						</Form>
					</Segment>
				</FlexItem>
			</Flex>
		</Base>
	);
};

const LoginHOC = compose(
	withState('dirty', 'setDirty', {}),
	withState('loading', 'setLoading', false),
	withState('error', 'setError', {}),
	withHandlers({
		handleChange: (props) => (e, { name, value }) => {
			const { dirty } = props;
			_.set(dirty, name, value);
			return props.setDirty(dirty);
		},
		handleSubmit: (props) => async (e, p) => {
			props.setLoading(true);
			const retval = await pengguna.login(props.dirty);
			if (retval.status_code === 200) {
				_.get(props, 'user.checkStatus', () => null)();
			} else { props.setError({ header: retval.message }); }
			return props.setLoading(false);
		},
		redirect: (props) => () => {
			console.log('redirect to dashboard');
			return props.history.push('/');
		}
 	}),
	lifecycle({
		componentDidMount() {
			const { user } = this.props;
			if (_.get(user, 'data.is_login', false)) { this.props.redirect(); }
		}
	})
)(Login);

export default (props) => <UserContext.Consumer>{(user) => <LoginHOC {...props} user={user} />}</UserContext.Consumer>;