import React from 'react';
import styled from 'styled-components';

const FlexItem = styled.div`	
	${({ padding }) => padding && `padding: ${padding};`}	

	${({ grow }) => grow && `flex-grow: ${grow};`}	
	${({ basis }) => basis && `flex-basis: ${basis};`}	
	${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export default (props) => <FlexItem {...props}/>;